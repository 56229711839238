import { getFirestore } from "firebase/firestore";
import {
  collection,
  query,
  where,
  getDoc,
  getDocs,
  doc,
  setDoc,
  orderBy,
  deleteDoc,
  // addDoc,
  runTransaction,
} from "firebase/firestore";

import { getAuth } from "firebase/auth";

const db = getFirestore();

export class brokerageFeesService {
  /**
   * 新增經濟費
   * @param {*} data
   * @param {*} date
   */
  static async set(data, date) {
    const now = new Date();

    const auth = getAuth();
    const user = auth.currentUser;

    const setData = {
      subtotal: 0,
      entryTime: now,
      data: {},
      year: date.year,
      month: date.month,
      creator: user.email,
    };

    const docId =
      String(date.year) +
      "-" +
      String(date.month).padStart(2, "0") +
      "-" +
      now.getTime();

    for (let i = 0; i < data.length; i++) {
      const memberSnap = await getDoc(doc(db, "members", data[i].memberId));
      if (memberSnap.exists()) {
        setData.data[data[i].memberId] = {};

        const member = memberSnap.data();

        if (member.member_id) {
          setData.data[data[i].memberId]["member_id"] = member.member_id;
        }

        if (member.name) {
          setData.data[data[i].memberId]["name"] = member.name;
        }

        if (member.mobile) {
          setData.data[data[i].memberId]["mobile"] = member.mobile;
        }

        if (member.store) {
          setData.data[data[i].memberId]["store"] = member.store;
        }

        if (member.numberStageName) {
          setData.data[data[i].memberId]["numberStageName"] =
            member.numberStageName;
        }

        if (member.role) {
          setData.data[data[i].memberId]["role"] = member.role;
        }

        if (data[i].hoursOnStage) {
          setData.data[data[i].memberId]["hoursOnStage"] = data[i].hoursOnStage;
        }

        if (data[i].daysAtWork) {
          setData.data[data[i].memberId]["daysAtWork"] = data[i].daysAtWork;
        }

        if (data[i].salary) {
          setData.data[data[i].memberId]["salary"] = data[i].salary;
        }

        setData.data[data[i].memberId]["brokerageFees"] = data[i].brokerageFees;

        setData.subtotal += data[i].brokerageFees;
      }
    }

    // 儲存經濟費紀錄
    await setDoc(doc(db, "monthlyBrokerageFees", docId), setData);
  }

  /**
   * 根據 年、月 取得經濟費資料
   * @param {*} year
   * @param {*} month
   * @returns
   */
  static async getByYearMonth(year, month, audited) {
    let monthlyBrokerageFees_CollectionName;

    if (audited === true) {
      monthlyBrokerageFees_CollectionName = "monthlyBrokerageFees_Audited";
    } else {
      monthlyBrokerageFees_CollectionName = "monthlyBrokerageFees";
    }

    // 抓取指定的年月經濟費資料
    const feesRef = collection(db, monthlyBrokerageFees_CollectionName);
    let q;
    if (audited === true) {
      q = query(
        feesRef,
        where("year", "==", year),
        where("month", "==", month)
      );
    } else {
      q = query(
        feesRef,
        orderBy("entryTime", "desc"),
        where("year", "==", year),
        where("month", "==", month)
      );
    }

    const result = [];
    let total = 0;

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const docRow = doc.data();
      for (let k in docRow.data) {
        let creator;

        if (audited === true) {
          creator = docRow.data[k].creator;
        } else {
          creator = docRow.creator;
        }

        result.push({
          docId: doc.id,
          year: docRow.year,
          month: docRow.month,
          memberId: k, //member document id
          member_id: docRow.data[k].member_id, //會員編號
          no: docRow.data[k].no, //審核編號
          name: docRow.data[k].name,
          store: docRow.data[k].store,
          numberStageName: docRow.data[k].numberStageName,
          mobile: docRow.data[k].mobile,
          memberFees: docRow.data[k].brokerageFees,
          hoursOnStage: docRow.data[k].hoursOnStage,
          daysAtWork: docRow.data[k].daysAtWork,
          salary: docRow.data[k].salary,
          entryTime: docRow.entryTime,
          creator: creator,
          role: docRow.data[k].role,
        });

        total += docRow.data[k].brokerageFees;
      }
    });

    // sort by memberId, 避免每次從firetore取map的順序不一樣
    result.sort(function (a, b) {
      const nameA = a.memberId.toUpperCase();
      const nameB = b.memberId.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return { data: result, count: result.length, total: total };
  }

  static async getBySearch(startDate, endDate, selectedDate, audited) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    endDate.setDate(endDate.getDate() + 1);

    let monthlyBrokerageFees_CollectionName;
    if (audited === true) {
      monthlyBrokerageFees_CollectionName = "monthlyBrokerageFees_Audited";
    } else {
      monthlyBrokerageFees_CollectionName = "monthlyBrokerageFees";
    }

    const feesRef = collection(db, monthlyBrokerageFees_CollectionName);

    let q;
    if (audited === true) {
      q = query(
        feesRef,
        where("year", "==", selectedDate.year),
        where("month", "==", selectedDate.month)
      );
    } else {
      q = query(
        feesRef,
        where("entryTime", ">=", startDate),
        where("entryTime", "<=", endDate),
        orderBy("entryTime", "desc")
      );
    }

    const result = [];
    let total = 0;

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const docRow = doc.data();
      for (let k in docRow.data) {
        let creator;

        if (audited === true) {
          creator = docRow.data[k].creator;
        } else {
          creator = docRow.creator;
        }

        result.push({
          docId: doc.id,
          year: docRow.year,
          month: docRow.month,
          memberId: k, //member document id
          member_id: docRow.data[k].member_id, //會員編號
          no: docRow.data[k].no, //審核編號
          name: docRow.data[k].name,
          store: docRow.data[k].store,
          mobile: docRow.data[k].mobile,
          memberFees: docRow.data[k].brokerageFees,
          hoursOnStage: docRow.data[k].hoursOnStage,
          daysAtWork: docRow.data[k].daysAtWork,
          salary: docRow.data[k].salary,
          entryTime: docRow.entryTime,
          creator: creator,
        });

        total += docRow.data[k].brokerageFees;
      }
    });

    // sort by memberId, 避免每次從firetore取map的順序不一樣
    result.sort(function (a, b) {
      const nameA = a.memberId.toUpperCase();
      const nameB = b.memberId.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    // console.log({ data: result, count: result.length, total: total })
    return { data: result, count: result.length, total: total };
  }

  /**
   * 根據docId與memberId取得該會員的經濟費金額
   * @param {*} docId
   * @param {*} memberId
   * @returns
   */
  static async getOneMemberFees(docId, memberId) {
    const docRef = doc(db, "monthlyBrokerageFees", docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const row = docSnap.data();

      // 傳回該會員的經濟費資料
      if (row.data[memberId] !== undefined) {
        return {
          memberId: memberId,
          brokerageFees: row.data[memberId].brokerageFees,
          hoursOnStage: row.data[memberId].hoursOnStage,
          daysAtWork: row.data[memberId].daysAtWork,
          salary: row.data[memberId].salary,
          year: row.year,
          month: row.month,
          memberData: {
            name: row.data[memberId].name,
            mobile: row.data[memberId].mobile,
          },
        };
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * 根據docId與memberId更新該會員的經濟費金額
   * @param {*} docId
   * @param {*} memberId
   * @param {*} brokerageFees
   */
  static async updateOneMemberFees(
    docId,
    memberId,
    brokerageFees,
    hoursOnStage,
    daysAtWork,
    salary
  ) {
    const docRef = doc(db, "monthlyBrokerageFees", docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const row = docSnap.data();
      row.data[memberId].brokerageFees = parseInt(brokerageFees); //經紀費金額

      row.data[memberId].hoursOnStage = hoursOnStage; //上台時數
      row.data[memberId].daysAtWork = daysAtWork; //上班天數
      row.data[memberId].salary = salary; //薪資金額

      let subtotal = 0;
      for (let k in row.data) {
        subtotal += row.data[k].brokerageFees;
      }

      row.subtotal = subtotal;

      await setDoc(doc(db, "monthlyBrokerageFees", docId), row);

      return true;
    } else {
      return false;
    }
  }

  /**
   * 根據docId與memberId刪除該會員的經濟費紀錄
   * @param {*} docId
   * @param {*} memberId
   * @returns
   */
  static async deleteOneMemberFees(docId, memberId) {
    const docRef = doc(db, "monthlyBrokerageFees", docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const row = docSnap.data();

      delete row.data[memberId];

      if (Object.keys(row.data).length === 0) {
        await deleteDoc(doc(db, "monthlyBrokerageFees", docId));
      } else {
        let subtotal = 0;
        for (let k in row.data) {
          subtotal += row.data[k].brokerageFees;
        }

        row.subtotal = subtotal;

        await setDoc(doc(db, "monthlyBrokerageFees", docId), row);
      }

      return true;
    } else {
      return false;
    }
  }

  /*
  static async audited(checkedMembers) {
    const auditedMemberId_Doc = [];

    // 處理 已審核 與 未審核
    for (let i = 0; i < checkedMembers.length; i++) {
      const member = checkedMembers[i];
      const docId = member.docId;

      // ------- 1. 配置分潤給上面三層的上線會員
      const audited_response = await this.audited_setBrokerageFees(
        member.memberId,
        member.memberId,
        member.memberFees,
        String(member.year),
        String(member.month).padStart(2, "0"),
        0
      );

      console.log("========================");

      // 如果配置上線分潤時有錯誤直接停止執行後續程序
      if (audited_response.result === 0) {
        alert(audited_response.msg);
        break;
      }

      // continue;

      // ------- 2. 寫至已審核的集合
      const auditedSnap = await getDoc(
        doc(db, "monthlyBrokerageFees_Audited", docId)
      );

      let auditedSetData;

      if (auditedSnap.exists()) {
        auditedSetData = auditedSnap.data();
        auditedSetData.data[member.memberId] = member.memberFees;
      } else {
        auditedSetData = {
          data: {
            [member.memberId]: member.memberFees,
          },
          month: member.month,
          year: member.year,
        };
      }

      // 經濟費總計
      let subtotal = 0;
      for (let k in auditedSetData.data) {
        subtotal += auditedSetData.data[k];
      }
      auditedSetData.subtotal = subtotal;

      await setDoc(
        doc(db, "monthlyBrokerageFees_Audited", docId),
        auditedSetData
      );

      // ------- 3. 從未審核的集合中刪除會員
      const feesSnap = await getDoc(doc(db, "monthlyBrokerageFees", docId));
      if (feesSnap.exists()) {
        const feesSet = feesSnap.data();

        delete feesSet.data[member.memberId];

        // 經濟費總計
        let subtotal = 0;
        for (let k in feesSet.data) {
          subtotal += feesSet.data[k];
        }
        feesSet.subtotal = subtotal;

        await setDoc(doc(db, "monthlyBrokerageFees", docId), feesSet);
      }

      // ------- 4. 將已經處理完的docId與memberId推入陣列內
      auditedMemberId_Doc.push(member.memberId + "|" + member.docId);
    }

    console.log("auditedMemberId_Doc =>", auditedMemberId_Doc);

    return auditedMemberId_Doc;
  }
  */

  /**
   *
   * @param {*} fromMemberId 產生經濟費的會員
   * @param {*} memberId 賦予分潤的會員
   * @param {*} memberFees 經濟費金額
   * @param {*} year 經濟費年份
   * @param {*} month 經濟費月份
   * @param {*} level 層級 (1~3)
   * @returns
   */
  /*
  static async audited_setBrokerageFees(
    fromMemberId,
    memberId,
    memberFees,
    year,
    month,
    level
  ) {
    //---------------- 參數初始 ----------------
    // 設定往上找幾層上線分潤
    const levelCount = 3;

    // 設定不同分潤比例在firestore的欄位名稱
    // 抓取目前的階層(level) 的分潤比例
    const levelBonusPercentageField = [
      "selfBonusPercentage",
      "Level1bonuspercentage",
      "Level2bonuspercentage",
      "Level3bonuspercentage",
    ];
    const nowBonusPercentage = levelBonusPercentageField[level];

    // 訂定不同階層在firestore的經濟費欄位名稱
    // 抓取目前的階層(level) 的經濟費欄位名稱
    const bonusDetailField = [
      "selfBonusDetail",
      "L1BonusDetail",
      "L2BonusDetail",
      "L3BonusDetail",
    ];
    const nowBonusDetail = bonusDetailField[level];

    //---------------- 處理經濟費 ----------------
    if (level <= levelCount) {
      const memberSnap = await getDoc(doc(db, "members", memberId));

      if (memberSnap.exists()) {
        const memberData = memberSnap.data();
        const rate = memberData[nowBonusPercentage];

        if (typeof rate === "number") {
          const brokerageFeesRef = doc(
            db,
            "members",
            memberId,
            "brokerageFees",
            `${year}${month}`
          );
          const brokerageFeesSnap = await getDoc(brokerageFeesRef);

          // 準備存入firestore的參數
          let brokerageFeesData = {};
          if (brokerageFeesSnap.exists()) {
            brokerageFeesData = brokerageFeesSnap.data();
          } else {
            brokerageFeesData = {
              L1BonusDetail: {},
              L2BonusDetail: {},
              L3BonusDetail: {},
              selfBonusDetail: 0,
              selfBonusPercentage: "",
              Level1bonuspercentage: "",
              Level2bonuspercentage: "",
              Level3bonuspercentage: "",
              brokerageFee: 0,
              bonusTotal: 0,
            };
          }

          brokerageFeesData["Level1bonuspercentage"] =
            memberData.Level1bonuspercentage;
          brokerageFeesData["Level2bonuspercentage"] =
            memberData.Level2bonuspercentage;
          brokerageFeesData["Level3bonuspercentage"] =
            memberData.Level3bonuspercentage;
          brokerageFeesData["selfBonusPercentage"] =
            memberData.selfBonusPercentage;

          // 分潤金額金額配置
          let setBonus = 0;
          if (rate > 0) {
            setBonus = (memberFees * rate) / 100;
          }

          if (nowBonusDetail === "selfBonusDetail" && level === 0) {
            //分潤
            brokerageFeesData[nowBonusDetail] = setBonus;
            //經濟費
            brokerageFeesData["brokerageFee"] = memberFees;
          } else {
            //分潤
            brokerageFeesData[nowBonusDetail][fromMemberId] = setBonus;
          }

          // 計算分潤總計
          let setBonusTotal = 0;
          bonusDetailField.forEach((e) => {
            if (e === "selfBonusDetail") {
              setBonusTotal += brokerageFeesData[e];
            } else {
              for (let detailKey in brokerageFeesData[e]) {
                setBonusTotal += brokerageFeesData[e][detailKey];
              }
            }
          });
          brokerageFeesData["bonusTotal"] = setBonusTotal;

          // 累計佣金 與 尚未提領佣金 加上目前的分潤金額，儲存至該會員doc內

          if (memberData["totalBonus"] !== undefined) {
            memberData["totalBonus"] += setBonus;
          } else {
            memberData["totalBonus"] = setBonus;
          }

          if (memberData["balance"] !== undefined) {
            memberData["balance"] += setBonus;
          } else {
            memberData["balance"] = setBonus;
          }

          await setDoc(doc(db, "members", memberId), memberData);

          // console.log("total: ", setBonusTotal)
          // console.log('=> ', nowBonusDetail, brokerageFeesData[nowBonusDetail]);
          console.log(
            `"${memberData.name} 分得 ${memberFees} 的 ${rate}％  為：${setBonusTotal},,, 儲存欄位為 ${nowBonusDetail}"`
          );
          console.log(memberData["totalBonus"], memberData["balance"]);
          console.log("---");

          // 儲存該會員的經濟費金額
          await setDoc(brokerageFeesRef, brokerageFeesData);
        } else {
          return {
            result: 0,
            msg: `"${memberData.name} 的 ${nowBonusPercentage} 分潤比例設定有問題, 系統停止執行"`,
          };
        }

        // 如果有上線才跑遞迴
        if (memberData.parentMemberId) {
          return this.audited_setBrokerageFees(
            fromMemberId,
            memberData.parentMemberId,
            memberFees,
            year,
            month,
            level + 1
          );
        }
      }
    }

    //---------------- 成功回傳 ----------------
    return { result: 1 };
  }
  */

  static async calcTriggle(checkedMembers) {
    try {
      const result = await runTransaction(db, async (transaction) => {
        const calcResult = await this.calc(checkedMembers, transaction);

        return calcResult;
      });

      // console.log('calcResult=>', result);
      return result;
    } catch (e) {
      alert(e);
    }
  }

  static async calc(checkedMembers, t) {
    //---------------- 參數初始 ----------------

    //--- 設定往上找幾層上線分潤
    const levelLimit = 3;

    //--- 設定不同分潤比例在firestore的欄位名稱
    const levelBonusPercentageField = [
      "selfBonusPercentage",
      "Level1bonuspercentage",
      "Level2bonuspercentage",
      "Level3bonuspercentage",
    ];

    //--- 不同階層所設定的訊息
    const levelTops = ["top0", "top1", "top2", "top3"];

    //--- 訂定不同階層在firestore的經濟費欄位名稱
    const bonusDetailField = [
      "selfBonusDetail",
      "L1BonusDetail",
      "L2BonusDetail",
      "L3BonusDetail",
    ];

    //--- 各種下層陣列名稱
    const nextLevelField = [
      "nextLevel1MembersId",
      "nextLevel2MembersId",
      "nextLevel3MembersId",
    ];

    //--- 抓取ratio setting
    const ratioRef = doc(db, "setting", "gx_profitSharingRatio");
    const ratioSnap = await t.get(ratioRef);
    let ratioSetting;

    if (ratioSnap.exists()) {
      ratioSetting = ratioSnap.data();
    } else {
      throw "setting Document does not exist!";
    }

    // 取得目前的時間
    const now = new Date();
    const now_y = now.getFullYear();
    const now_m = ("0" + (now.getMonth() + 1)).slice(-2);
    const now_d = ("0" + now.getDate()).slice(-2);
    const now_h = ("0" + now.getHours()).slice(-2);
    const now_min = ("0" + now.getMinutes()).slice(-2);
    const now_s = ("0" + now.getSeconds()).slice(-2);

    //--- 其他參數
    let allSetBonus = 0; //這次所有的會員分潤金額
    let resultMsg = {};
    let members = {};
    let setBrokerageFeesDataNo = []; //替未審核的經紀費資料設定單號 (假如已經有設定過單號的就不會再設定)

    //---------------- 參數初始 ----------------

    for (let i = 0; i < checkedMembers.length; i++) {
      const checkedMember = checkedMembers[i];
      const memberFees = checkedMember.memberFees;

      // let memberId與levelNow為 while迴圈的初始值，之後每跑一次迴圈會變動
      let memberId = checkedMember.memberId;
      let levelNow = 0;

      // 是否跳出while迴圈
      let breakWhile = false;

      // 設定分潤顯示的結果
      resultMsg[checkedMember.memberId + "|" + checkedMember.docId] = {
        top0: {}, //暫時用不到
        top1: {},
        top2: {},
        top3: {},
        name: checkedMember.name,
        role: checkedMember.role,
        member_id: checkedMember.member_id,
        store: checkedMember.store,
        numberStageName: checkedMember.numberStageName,
        memberFees: memberFees,
        hoursOnStage: checkedMember.hoursOnStage,
        daysAtWork: checkedMember.daysAtWork,
        salary: checkedMember.salary,
      };

      // 假如經紀費金額非數字, 停止執行
      if (memberFees === undefined || typeof memberFees !== "number") {
        throw "經紀費金額非數字型態, 系統停止執行";
      }

      // 開始跑找上線的迴圈
      while (levelNow <= levelLimit) {
        if (!members[memberId]) {
          const memberDocRef = doc(db, "members", memberId);
          const memberSnap = await t.get(memberDocRef);

          // 是否找得到該會員, 找不到就跳出while迴圈, 繼續跑下一個被選取的會員
          if (memberSnap.exists()) {
            // 1. 取得會員基本資料
            const memberData = memberSnap.data();

            // 2. 取得經紀費分潤集合，假如不存在則放一個預設的資料
            const brokerageFeesDocRef = doc(
              db,
              "members",
              memberId,
              "brokerageFees",
              `${checkedMember.year}${checkedMember.month}`
            );

            const brokerageFeesSnap = await t.get(brokerageFeesDocRef);

            let brokerageFeesData = {};
            let brokerageFeesExisted;
            if (brokerageFeesSnap.exists()) {
              brokerageFeesData = brokerageFeesSnap.data();
              brokerageFeesExisted = true;
            } else {
              brokerageFeesData = {
                L1BonusDetail: {},
                L2BonusDetail: {},
                L3BonusDetail: {},
                Level1bonuspercentage: 0,
                Level2bonuspercentage: 0,
                Level3bonuspercentage: 0,
                bonusTotal: 0,
                brokerageFee: 0,
                selfBonusDetail: 0,
                selfBonusPercentage: 0,
              };
              brokerageFeesExisted = false;
            }

            // 3. 將需要的資料通通放到會員陣列參數內
            members[memberId] = {
              memberRef: memberDocRef,
              brokerageFeesRef: brokerageFeesDocRef,
              data: memberData,
              brokerageFees: brokerageFeesData,
              brokerageFeesExisted: brokerageFeesExisted,
              time: { year: checkedMember.year, month: checkedMember.month },
              setTotalBonus: 0,
              transactionLog: "",
              monthlyBrokerageFeesDocId: checkedMember.docId,
            };
          } else {
            // 假如找不到目前該會員則跳出找上線的while迴圈，繼續跑checkedMembers的for迴圈
            console.log("member no exist");
            break;
          }
        }
        // end if !members[memberId] 配置會員資料至陣列中

        // console.log("----------------level ", levelNow);
        // console.log("name: ", members[memberId].data.name);

        //-------- 目前客戶的邏輯為產生經紀費的會員不抽自身分潤了，因此先加這段 continue
        if (levelNow === 0) {
          members[memberId]["brokerageFees"]["brokerageFee"] += memberFees;
          // console.log(members[memberId]);

          if (members[memberId].data.parentMemberId) {
            memberId = members[memberId].data.parentMemberId;
          } else {
            break;
          }

          levelNow++;
          continue;
        }

        //-------- 如果是已經停用的會員也跑 continue
        if (
          members[memberId].data.status &&
          members[memberId].data.status === "delete"
        ) {
          console.log(members[memberId].data.name, "is delete");

          if (members[memberId].data.parentMemberId) {
            memberId = members[memberId].data.parentMemberId;
          } else {
            break;
          }

          levelNow++;
          continue;
        }

        //------------------------------------ 1. 取得目前的階層要分潤的趴數欄位 ------------------------------------
        let nowRate;
        const nowBonusPercentageField = levelBonusPercentageField[levelNow];
        const nowNextLevelField = nextLevelField[levelNow - 1]; // 必須減一，因為只有1~3層，0並不會有

        // console.log("setting分潤", nowNextLevelField, nowBonusPercentageField);

        // 分潤趴數的決定：
        // 1.假如是經紀人且是第一層會員，則依照 ratioSetting agent
        // 2.假如是合作經紀公司且第一層會員，則依照 ratioSetting agentCompany<A, B>
        // 3.依照setting的分潤趴數
        if (
          levelNow === 1 &&
          members[memberId].data.agent &&
          members[memberId].data.agent === true
        ) {
          nowRate = ratioSetting["agent"];
          breakWhile = true;
        } else if (
          levelNow === 1 &&
          (members[memberId].data.role === "agentCompanyA" ||
            members[memberId].data.role === "agentCompanyB")
        ) {
          nowRate = ratioSetting[members[memberId].data.role];
          breakWhile = true;
        } else if (
          members[memberId]["data"][nowNextLevelField] &&
          typeof members[memberId]["data"][nowNextLevelField] === "object"
        ) {
          nowRate = this.getMemberRate(
            Object.keys(members[memberId]["data"][nowNextLevelField]).length,
            ratioSetting,
            nowBonusPercentageField
          );
        } else {
          nowRate = 0;
        }

        members[memberId].brokerageFees[nowBonusPercentageField] = nowRate;

        /**
        *
        * 預留的邏輯，之後客戶需要的話可以補在下面
        *
        * 1. 假如自身分潤有設定的話
         if (
           nowBonusPercentageField === "selfBonusPercentage" &&
           levelNow === 0
         ) {
           if (members[memberId]["data"]["selfBonusPercentage"] && members[memberId]["data"]["selfBonusPercentage"] > 0) {
             console.log("yes 自身分潤");
             nowRate = members[memberId]["data"][nowBonusPercentageField];
           } else {
             console.log("no 自身分潤");
             nowRate = 0;
           }
         }

         2. 假如自訂分潤有設定的話
         else if (members[memberId]["data"][nowBonusPercentageField] && members[memberId]["data"][nowBonusPercentageField] > 0) {
           // 假如有自 “訂” 分潤
           nowRate = members[memberId]["data"][nowBonusPercentageField];
           console.log('自訂分潤', nowBonusPercentageField);
         }
        *
        */

        // console.log("nowRate", nowRate, "%");
        // console.log("memberFees", memberFees);

        // 假如其中一個會員的分潤的趴數有問題則停止程式
        /*** */
        if (nowRate === undefined || typeof nowRate !== "number") {
          throw `"${members[memberId].data.name} 的 ${nowBonusPercentageField} 分潤比例設定有問題, 系統停止執行, 請先檢查完再繼續"`;
        }

        //------------------------------------ 2. 依照趴數換算獎金，分配至欄位內 ------------------------------------
        // 分潤金額金額配置
        let setBonus = 0;
        if (nowRate > 0) {
          setBonus = (memberFees * nowRate) / 100;
          setBonus = Math.round(setBonus);
          allSetBonus += setBonus;
        }

        // console.log("setBonus=>", setBonus);

        // 目前要配置獎金的欄位名稱
        const nowBonusDetailField = bonusDetailField[levelNow];

        /**
         * 
          由於自身分潤暫時不計算了，因此先將此邏輯拿掉，之後有需要再補

          if (nowBonusDetailField === "selfBonusDetail" && levelNow === 0) {
            members[memberId]["brokerageFees"][nowBonusDetailField] += setBonus;
            members[memberId]["brokerageFees"]["brokerageFee"] += memberFees;
          } else {
            .... 在這邊把下面的程式碼補進來即可
          }
         */

        if (
          members[memberId]["brokerageFees"][nowBonusDetailField][
            checkedMember.memberId
          ] === undefined
        ) {
          members[memberId]["brokerageFees"][nowBonusDetailField][
            checkedMember.memberId
          ] = setBonus;
        } else {
          members[memberId]["brokerageFees"][nowBonusDetailField][
            checkedMember.memberId
          ] += setBonus;
        }

        // console.log("brokerageFees", members[memberId].brokerageFees);

        // 計算該會員本次審核總共獲得多少分潤金額，
        // 不能在audit()時用brokerageFees集合的bonusTotal，因為可能一個月多次審核(雖然很少)，
        // 所以應該以每次增加的金額來統計，不能用已統計完的brokerageFees集合的bonusTotal 寫入至會員集合中的totalBonus與balance
        members[memberId]["setTotalBonus"] += setBonus;

        // 設定分潤顯示的結果
        const nowLevelTops = levelTops[levelNow];

        resultMsg[checkedMember.memberId + "|" + checkedMember.docId][
          nowLevelTops
        ]["name"] = members[memberId].data.name;

        resultMsg[checkedMember.memberId + "|" + checkedMember.docId][
          nowLevelTops
        ]["member_id"] = members[memberId].data.member_id;

        resultMsg[checkedMember.memberId + "|" + checkedMember.docId][
          nowLevelTops
        ]["rate"] = nowRate;

        resultMsg[checkedMember.memberId + "|" + checkedMember.docId][
          nowLevelTops
        ]["setBonus"] = setBonus;

        resultMsg[checkedMember.memberId + "|" + checkedMember.docId][
          nowLevelTops
        ]["memberDocumentId"] = memberId;

        if (members[memberId].data.agent) {
          resultMsg[checkedMember.memberId + "|" + checkedMember.docId][
            nowLevelTops
          ]["agent"] = members[memberId].data.agent;
        }

        if (members[memberId].data.role) {
          resultMsg[checkedMember.memberId + "|" + checkedMember.docId][
            nowLevelTops
          ]["role"] = members[memberId].data.role;
        }

        // 分潤Log
        members[memberId].transactionLog +=
          `由 ${checkedMember.name} 的經紀費 $${memberFees}，分潤 $${setBonus} (${nowRate}%)。` +
          "\n";
        // console.log("log", members[memberId].transactionLog);

        //------------------------------ 3. 如果 沒有上線 或 程式有指定跳出迴圈的參數 否則跳出while ------------------------------
        // 否則 重新配置memberId為上線的id，繼續跑while迴圈
        if (!members[memberId].data.parentMemberId || breakWhile === true) {
          break;
        } else {
          memberId = members[memberId].data.parentMemberId;
        }

        levelNow++;
      }
      // end of while 找上線的迴圈

      //------------------------------ for 迴圈內繼續處理其他資訊 ------------------------------

      // 配置 產生經紀費的會員 是否為「經紀人」身份
      if (members[checkedMember.memberId].data.agent === true) {
        resultMsg[checkedMember.memberId + "|" + checkedMember.docId]["agent"] =
          members[checkedMember.memberId].data.agent;
      }

      // 配置 產生經紀費的會員 的分潤單號
      if (!checkedMember.no) {
        const setNo =
          String(now_y).slice(-2) +
          String(now_m) +
          String(now_d) +
          String(now_h) +
          String(now_min) +
          String(now_s) +
          String(i + 1);

        checkedMembers[i].no = setNo;

        setBrokerageFeesDataNo.push({
          docId: checkedMembers[i].docId,
          memberId: checkedMembers[i].memberId,
          no: setNo,
        });
      }

      resultMsg[checkedMember.memberId + "|" + checkedMember.docId]["no"] =
        checkedMembers[i].no;

      // 配置目前時間，之後需要寫到已審核集合查詢用的
      resultMsg[checkedMember.memberId + "|" + checkedMember.docId][
        "entryTime"
      ] = now;

      // 配置 產生經紀費的會員 的「上1~3線」的分潤總計
      let resultMsgSubtotal = 0;
      if (
        resultMsg[checkedMember.memberId + "|" + checkedMember.docId]["top1"][
          "setBonus"
        ]
      ) {
        resultMsgSubtotal +=
          resultMsg[checkedMember.memberId + "|" + checkedMember.docId]["top1"][
            "setBonus"
          ];
      }
      if (
        resultMsg[checkedMember.memberId + "|" + checkedMember.docId]["top2"][
          "setBonus"
        ]
      ) {
        resultMsgSubtotal +=
          resultMsg[checkedMember.memberId + "|" + checkedMember.docId]["top2"][
            "setBonus"
          ];
      }
      if (
        resultMsg[checkedMember.memberId + "|" + checkedMember.docId]["top3"][
          "setBonus"
        ]
      ) {
        resultMsgSubtotal +=
          resultMsg[checkedMember.memberId + "|" + checkedMember.docId]["top3"][
            "setBonus"
          ];
      }
      resultMsg[checkedMember.memberId + "|" + checkedMember.docId][
        "subtotalBonus"
      ] = resultMsgSubtotal;
    }
    // end of for, 結束 checkedMembers 的迴圈

    //------------------------ 替每一筆經紀費設定單號 ------------------------

    // 替每筆經紀費審核加上編號
    setBrokerageFeesDataNo.forEach((item, index) => {
      console.log("no>>>> ", index, item);
      t.update(doc(db, "monthlyBrokerageFees", item.docId), {
        [`data.${item.memberId}.no`]: item.no,
      });
    });

    //------------------------ 統計每一位會員的分潤總計，這是為了統計members子集合brokerageFees內的bonusTotal欄位 ------------------------
    for (let id in members) {
      let setBonusTotal = 0;

      bonusDetailField.forEach((field) => {
        if (field === "selfBonusDetail") {
          setBonusTotal += members[id]["brokerageFees"][field];
        } else {
          for (let detailKey in members[id]["brokerageFees"][field]) {
            setBonusTotal += members[id]["brokerageFees"][field][detailKey];
          }
        }
      });
      members[id]["brokerageFees"]["bonusTotal"] = setBonusTotal;
    }

    // console.log(">>>>>>>>>>");
    // console.log(members);

    //------------------------------ 統計整個試算結果的各種總計 ------------------------------
    let top1SetBonusTotal = 0;
    let top2SetBonusTotal = 0;
    let top3SetBonusTotal = 0;
    let allMemberFeesTotal = 0;
    let salaryTotal = 0;
    const allMemberId = [];

    for (let id in resultMsg) {
      //1. 上1線分潤總和
      if (resultMsg[id].top1.setBonus) {
        top1SetBonusTotal += parseInt(resultMsg[id].top1.setBonus);
      }

      //2. 上2線分潤總和
      if (resultMsg[id].top2.setBonus) {
        top2SetBonusTotal += parseInt(resultMsg[id].top2.setBonus);
      }

      //3. 上3線分潤總和
      if (resultMsg[id].top3.setBonus) {
        top3SetBonusTotal += parseInt(resultMsg[id].top3.setBonus);
      }

      //4. 薪資金額總和
      if (resultMsg[id].salary) {
        salaryTotal += parseInt(resultMsg[id].salary);
      }

      //5. 經濟費用總和
      allMemberFeesTotal += parseInt(resultMsg[id].memberFees);

      //6. 會員數(不重複的)
      if (resultMsg[id].member_id) {
        if (!allMemberId.includes(resultMsg[id].member_id)) {
          allMemberId.push(resultMsg[id].member_id);
        }
      }
    }

    // 整個試算結果的各種總計
    const allCalcdTotal = {
      top1SetBonusTotal: top1SetBonusTotal,
      top2SetBonusTotal: top2SetBonusTotal,
      top3SetBonusTotal: top3SetBonusTotal,
      allMemberFeesTotal: allMemberFeesTotal,
      salaryTotal: salaryTotal,
      allMemberIdCount: allMemberId.length,
    };

    return {
      result: 1,
      checkedMembers: checkedMembers,
      brokerageFeesResultMsg: resultMsg,
      brokerageFeesResultMsgCount: Object.keys(resultMsg).length,
      brokerageFeesResult: members,
      allSetBonus: allSetBonus,
      allCalcdTotal: allCalcdTotal,
    };
  }

  static async audit(checkedMembers) {
    const auth = getAuth();
    const user = auth.currentUser;

    try {
      const result = await runTransaction(db, async (transaction) => {
        const calcResult = await this.calc(checkedMembers, transaction);

        let auditedMemberId_Doc = [];
        let monthlyBrokerageFeesCollection = {};
        // let monthlyBrokerageFees_AuditedCollection = {};
        for (let i = 0; i < checkedMembers.length; i++) {
          const docId = checkedMembers[i].docId;
          const memberId = checkedMembers[i].memberId;

          /*
          const member_id = checkedMembers[i].member_id; //會員編號
          const store = checkedMembers[i].store; //服務店家
          const name = checkedMembers[i].name;
          const memberFees = checkedMembers[i].memberFees;
          const year = checkedMembers[i].year;
          const month = checkedMembers[i].month;
          */

          // 從未審核的名單中刪除該會員
          if (!monthlyBrokerageFeesCollection[docId]) {
            const feesSnap = await transaction.get(
              doc(db, "monthlyBrokerageFees", docId)
            );
            if (feesSnap.exists()) {
              monthlyBrokerageFeesCollection[docId] = feesSnap.data();
            }
          }
          delete monthlyBrokerageFeesCollection[docId].data[memberId];

          auditedMemberId_Doc.push(memberId + "|" + docId);
        }

        const datas = calcResult.brokerageFeesResult;
        console.log("return calcResult~", calcResult);
        console.log("datas", datas);

        for (let id in datas) {
          const setBonus = datas[id]["setTotalBonus"];

          // 1. 寫入 會員 經紀費分潤集合
          transaction.set(
            datas[id]["brokerageFeesRef"],
            datas[id]["brokerageFees"]
          );

          // 2. 寫入 會員 集合
          if (datas[id]["data"]["totalBonus"] !== undefined) {
            datas[id]["data"]["totalBonus"] += setBonus;
          } else {
            datas[id]["data"]["totalBonus"] = setBonus;
          }

          if (datas[id]["data"]["balance"] !== undefined) {
            datas[id]["data"]["balance"] += setBonus;
          } else {
            datas[id]["data"]["balance"] = setBonus;
          }

          transaction.set(datas[id]["memberRef"], datas[id]["data"]);

          // 3. 寫入會員 交易 紀錄結合
          if (typeof setBonus === "number" && setBonus > 0) {
            const trans = {
              balance: datas[id]["data"]["balance"],
              bonus: setBonus,
              doType: "in",
              entryTime: new Date(),
              transactionType: "分潤G幣",
              creator: user.email,
              memberDocId: id,
              remark: datas[id]["transactionLog"],
            };

            if (datas[id]["data"]["name"]) {
              trans["member_name"] = datas[id]["data"]["name"];
            }

            if (datas[id]["data"]["member_id"]) {
              trans["member_id"] = datas[id]["data"]["member_id"];
            }

            if (datas[id]["data"]["store"]) {
              trans["member_store"] = datas[id]["data"]["store"];
            }

            // 增加編號藝名
            if (datas[id]["data"]["numberStageName"]) {
              trans["member_numberStageName"] =
                datas[id]["data"]["numberStageName"];
            }

            // create member's transaction ref
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth() + 1;
            const date = now.getDate();
            const transactionId =
              String(year) +
              String(month) +
              String(date) +
              "-" +
              now.getTime() +
              "-" +
              Math.floor(Math.random() * 100000);

            const transactionRef = doc(
              db,
              "members",
              id,
              "transaction",
              transactionId
            );

            transaction.set(transactionRef, trans);
          }
        }

        // 統計
        // todolist : 之後 transaction delete,set 內的ref改成參數，抓上面已經建立過的ref(要存起來) 2021/11/26
        for (let docId in monthlyBrokerageFeesCollection) {
          const collection = monthlyBrokerageFeesCollection[docId];

          if (Object.keys(collection.data).length === 0) {
            // 如果未審核的data map已經是空的了，代表已經都審核完畢了，這個doc就可以刪除了
            transaction.delete(doc(db, "monthlyBrokerageFees", docId));
          } else {
            // 重新計算小計
            let subtotal = 0;
            for (let mid in collection.data) {
              subtotal += collection.data[mid].brokerageFees;
            }

            collection.subtotal = subtotal;

            transaction.set(doc(db, "monthlyBrokerageFees", docId), collection);
          }
        }

        // 寫入已審核紀錄
        let auditedCount = 1;
        const auditedNow = new Date().getTime();
        for (let id in calcResult.brokerageFeesResultMsg) {
          const setId = String(auditedNow) + "_" + String(auditedCount);

          transaction.set(
            doc(db, "monthlyBrokerageFees_Audited", setId),
            calcResult.brokerageFeesResultMsg[id]
          );

          auditedCount += 1;
        }

        return auditedMemberId_Doc;
      });

      return result;
    } catch (e) {
      alert(e);
    }

    /**

    // console.log(datas);
    // return false;
    // console.log(checkedMembers);
    // console.log("-------------------");

    // const auth = getAuth();
    // const user = auth.currentUser;

    for (let id in datas) {
      // console.log(id);
      // console.log(datas[id]["time"]["year"], datas[id]["time"]["month"]);
      // console.log(datas[id]["data"]);
      // console.log(datas[id]["brokerageFees"]);
      // console.log(datas[id]["setTotalBonus"]);
      // console.log(datas[id]["monthlyBrokerageFeesDocId"]);
      // console.log("---");

      const setBonus = datas[id]["setTotalBonus"];

      // 1. 寫入 會員 經紀費分潤集合
      await setDoc(
        doc(
          db,
          "members",
          id,
          "brokerageFees",
          `${datas[id]["time"]["year"]}${datas[id]["time"]["month"]}`
        ),
        datas[id]["brokerageFees"]
      );

      // 2. 寫入 會員 集合
      if (datas[id]["data"]["totalBonus"] !== undefined) {
        datas[id]["data"]["totalBonus"] += setBonus;
      } else {
        datas[id]["data"]["totalBonus"] = setBonus;
      }

      if (datas[id]["data"]["balance"] !== undefined) {
        datas[id]["data"]["balance"] += setBonus;
      } else {
        datas[id]["data"]["balance"] = setBonus;
      }

      await setDoc(doc(db, "members", id), datas[id]["data"]);

      // 3. 寫入會員 交易 紀錄結合
      if (typeof setBonus === "number" && setBonus > 0) {
        const trans = {
          balance: datas[id]["data"]["balance"],
          bonus: setBonus,
          doType: "in",
          entryTime: new Date(),
          transactionType: "分潤G幣",
          creator: user.email,
          memberDocId: id,
        };

        if (datas[id]["data"]["name"]) {
          trans["member_name"] = datas[id]["data"]["name"];
        }

        if (datas[id]["data"]["member_id"]) {
          trans["member_id"] = datas[id]["data"]["member_id"];
        }

        if (datas[id]["data"]["store"]) {
          trans["member_store"] = datas[id]["data"]["store"];
        }

        await addDoc(collection(db, "members", id, "transaction"), trans);
      }
    }

    let auditedMemberId_Doc = [];
    let monthlyBrokerageFeesCollection = {};
    let monthlyBrokerageFees_AuditedCollection = {};
    for (let i = 0; i < checkedMembers.length; i++) {
      const docId = checkedMembers[i].docId;
      const memberId = checkedMembers[i].memberId;
      const member_id = checkedMembers[i].member_id; //會員編號
      const store = checkedMembers[i].store; //服務店家
      const name = checkedMembers[i].name;
      const memberFees = checkedMembers[i].memberFees;
      const year = checkedMembers[i].year;
      const month = checkedMembers[i].month;

      // 從未審核的名單中刪除該會員
      if (!monthlyBrokerageFeesCollection[docId]) {
        const feesSnap = await getDoc(doc(db, "monthlyBrokerageFees", docId));
        if (feesSnap.exists()) {
          monthlyBrokerageFeesCollection[docId] = feesSnap.data();
        }
      }
      delete monthlyBrokerageFeesCollection[docId].data[memberId];

      // 從已審核的名單中增加該會員
      if (!monthlyBrokerageFees_AuditedCollection[docId]) {
        const auditedSnap = await getDoc(
          doc(db, "monthlyBrokerageFees_Audited", docId)
        );
        if (auditedSnap.exists()) {
          monthlyBrokerageFees_AuditedCollection[docId] = auditedSnap.data();
        } else {
          monthlyBrokerageFees_AuditedCollection[docId] = {
            data: {},
            year: year,
            month: month,
          };
        }
      }

      monthlyBrokerageFees_AuditedCollection[docId].data[memberId] = {};

      if (name) {
        monthlyBrokerageFees_AuditedCollection[docId].data[memberId]["name"] =
          name;
      }

      if (store) {
        monthlyBrokerageFees_AuditedCollection[docId].data[memberId]["store"] =
          store;
      }

      if (member_id) {
        monthlyBrokerageFees_AuditedCollection[docId].data[memberId][
          "member_id"
        ] = member_id;
      }

      monthlyBrokerageFees_AuditedCollection[docId].data[memberId][
        "brokerageFees"
      ] = memberFees;

      monthlyBrokerageFees_AuditedCollection[docId].data[memberId]["creator"] =
        user.email;

      auditedMemberId_Doc.push(memberId + "|" + docId);
    }

    // 統計

    for (let docId in monthlyBrokerageFeesCollection) {
      const collection = monthlyBrokerageFeesCollection[docId];

      if (Object.keys(collection.data).length === 0) {
        // 如果未審核的data map已經是空的了，代表已經都審核完畢了，這個doc就可以刪除了
        await deleteDoc(doc(db, "monthlyBrokerageFees", docId));
      } else {
        // 重新計算小計
        let subtotal = 0;
        for (let mid in collection.data) {
          subtotal += collection.data[mid].brokerageFees;
        }

        collection.subtotal = subtotal;

        await setDoc(doc(db, "monthlyBrokerageFees", docId), collection);
      }
    }

    for (let docId in monthlyBrokerageFees_AuditedCollection) {
      const collection = monthlyBrokerageFees_AuditedCollection[docId];

      let subtotal = 0;
      for (let mid in collection.data) {
        subtotal += collection.data[mid].brokerageFees;
      }

      collection.subtotal = subtotal;

      await setDoc(doc(db, "monthlyBrokerageFees_Audited", docId), collection);
    }

    return auditedMemberId_Doc;

    **/
  }

  static getMemberRate(num, data, field) {
    let result = {};

    if (num >= data.gain.numberOfPeople) {
      result = {
        Level1bonuspercentage: data.gain.rate1,
        Level2bonuspercentage: data.gain.rate2,
        Level3bonuspercentage: data.gain.rate3,
      };
    } else if (num >= data.basic.numberOfPeople) {
      result = {
        Level1bonuspercentage: data.basic.rate1,
        Level2bonuspercentage: data.basic.rate2,
        Level3bonuspercentage: data.basic.rate3,
      };
    } else if (num <= 0) {
      result = {
        Level1bonuspercentage: 0,
        Level2bonuspercentage: 0,
        Level3bonuspercentage: 0,
      };
    }

    return result[field];
  }
}
